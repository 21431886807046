import { template as template_fa8c3f9c911744558d90000ce2e6a881 } from "@ember/template-compiler";
const FKText = template_fa8c3f9c911744558d90000ce2e6a881(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
