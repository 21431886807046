import { template as template_14566600bf12452d85ebb7cc785d5d2c } from "@ember/template-compiler";
const FKControlMenuContainer = template_14566600bf12452d85ebb7cc785d5d2c(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
